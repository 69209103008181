import React, { useState } from 'react';
import PrivacyPolicy from './../PrivacyPolicy/PrivacyPolicy'
import './Footer.scss'; 

const Footer = () => {
  const [activePP, setActivePP] = useState(false);

  return (
    <div>
      <footer className='footer is-primary'>
      <div className='container'>
        <div className='columns is-vcentered'>
          <div className='column'>
            <div className='content has-text-centered-mobile is-small'>
              <p className='Address'>
                ELOSPARK - Edificio 14 Rua da cruz, nº75
              </p>
              <p>Algueirão - 2725-193 Mem Martins</p>
            </div>
          </div>

          <div className='column has-text-centered'>
            <a onClick={() => setActivePP(true)}>
              <p>Politica de Privacidade</p>
            </a>
          </div>

          <div className='column has-text-centered'>
            <a  href="https://www.cniacc.pt/pt/" target='_blank'>
              <p>Resolução de Litigios</p>
            </a>
          </div>

          <div className='column is-narrow'>
            <a href="https://www.livroreclamacoes.pt/Inicio/" target='_blank'>
              <figure className="image livro_rec">
                <img src='/images/livro_reclamacoes.png'/>
              </figure>
            </a>
          </div>
          <div className='column has-text-right'>
            <p>Copyright © Rioquimica 2022</p>
          </div>
        </div>
      </div>
    </footer>
      <PrivacyPolicy  is_active={activePP}  closeModal={() => setActivePP(false)} />
    </div>
    
  );
};

export default Footer;
