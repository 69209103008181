const industriesList = [
  {
    id: 'rest',
    image: '/images/RestauracaoHotelaria.png',
    title: 'Restauração e Hotelaria',
  },
  {
    id: 'const',
    image: '/images/ConstrucaoCivil.png',
    title: 'Construção Civil',
  },
  {
    id: 'auto',
    image: '/images/Automovel.png',
    title: 'Automóvel',
  },
  {
    id: 'alim',
    image: '/images/AlimentaresPesca.png',
    title: 'Alimentares e Pesca',
  },
  {
    id: 'lava',
    image: '/images/Lavandaria.png',
    title: 'Lavandaria',
  },
  {
    id: 'agua',
    image: '/images/TratamentoAguas.png',
    title: 'Tratamento de Águas',
  },
  {
    id: 'elect',
    image: '/images/EletricidadeEletronica.png',
    title: 'Eletricidade e Eletrónica',
  },
  {
    id: 'metal',
    image: '/images/Metalomecanica.png',
    title: 'Metalomecânica',
  },
  {
    id: 'outro',
    image: '/images/Outros.png',
    title: 'Outros',
  },
];

export default industriesList;
