import React, { Component } from 'react';
import axios from 'axios';
import { Element } from 'react-scroll';
import './Contacts.scss';
import { phoneNumValidator, emailValidator } from '../../api/validators';


class Contacts extends Component {
  state = {
    isLoading: '',
    completeMsg: '',
    name: '',
    nameErr: '',
    email: '',
    emailErr: '',
    contact: '',
    contactErr: '',
    location: '',
    request: '',
    requestErr: '',
  };

  componentWillReceiveProps(nextProps) {
    this.setState({ request: nextProps.message });
  }

  handleSubmit = () => {
    this.setState({ isLoading: 'is-loading' });
    let error = false;
    // name
    if (!this.state.name) {
      error = true;
      this.setState({ nameErr: 'Por favor indique o seu nome' });
    }
    // contacts
    if (!this.state.email && !this.state.contact) {
      error = true;
      this.setState({
        emailErr: 'Por favor indique um email ou contacto telefónico',
      });
    } else if (this.state.email && !emailValidator(this.state.email)) {
      error = true;
      this.setState({ emailErr: 'Por favor indique um email válido' });
    }
    if (this.state.contact && !phoneNumValidator(this.state.contact)) {
      error = true;
      this.setState({
        contactErr: 'Por favor indique um número de telefone válido',
      });
    }
    // message
    if (!this.state.request) {
      error = true;
      this.setState({
        requestErr: 'Por favor indique o seu pedido/esclarecimento',
      });
    }

    if (!error) {
      const msg = {
        name: this.state.name,
        email: this.state.email,
        contact: this.state.contact,
        location: this.state.location,
        request: this.state.request,
      };
      return this.sendEmail(msg);
    }
    this.setState({ isLoading: '' });
  };

  sendEmail = (msg) => {
    axios
      .post(`${window.location.origin}/sendEmailRequest`, msg)
      .then(() => {
        console.log('enviado com sucesso');
        this.setState({
          isLoading: '',
          completeMsg: 'O seu pedido de contacto foi enviado com sucesso.',
        });
        setTimeout(() => this.setState({ completeMsg: '' }), 3000);
      })
      .catch(err => {
        console.log('erro no envio do email', err);
        this.setState({
          isLoading: '',
          completeMsg:
            'Ocorreu um erro. Por favor contacte-nos directamente para o e-mail rioquimica@mail.telepac.pt',
        });
        setTimeout(() => this.setState({ completeMsg: '' }), 3000);
      });
  };

  render() {
    return (
      <Element name='contacts' >
        <section className='section'>
          <div className='container'>
            <div className='columns'>
              <div className='column is-narrow'>
                <h1 className='title'>Contacte-nos</h1>
              </div>
            </div>
            <div className='columns is-centered'>
              <div className='column is-6'>
                <div className='field'>
                  <p className='control has-icons-left'>
                    <input
                      className='input'
                      type='text'
                      placeholder='Nome'
                      value={this.state.name}
                      onChange={ev =>
                        this.setState({ name: ev.target.value, nameErr: '' })
                      }
                    />
                    <span className='icon is-small is-left'>
                      <i className='fas fa-user' />
                    </span>
                  </p>
                  <p className='help is-danger'>{this.state.nameErr}</p>
                </div>
                <div className='field'>
                  <p className='control has-icons-left'>
                    <input
                      className='input'
                      type='email'
                      placeholder='Email'
                      value={this.state.email}
                      onChange={ev =>
                        this.setState({ email: ev.target.value, emailErr: '' })
                      }
                    />
                    <span className='icon is-small is-left'>
                      <i className='fas fa-envelope' />
                    </span>
                  </p>
                  <p className='help is-danger'>{this.state.emailErr}</p>
                </div>
                <div className='field'>
                  <div className='field-body'>
                    <div className='field'>
                      <p className='control has-icons-left'>
                        <input
                          className='input'
                          type='tel'
                          placeholder='Contacto Telefónico'
                          value={this.state.contact}
                          onChange={ev =>
                            this.setState({
                              contact: ev.target.value,
                              emailErr: '',
                            })
                          }
                        />
                        <span className='icon is-small is-left'>
                          <i className='fas fa-phone' />
                        </span>
                      </p>
                      <p className='help is-danger'>{this.state.contactErr}</p>
                    </div>
                    <div className='field'>
                      <p className='control has-icons-left'>
                        <input
                          className='input'
                          type='text'
                          placeholder='Localidade'
                          value={this.state.location}
                          onChange={ev =>
                            this.setState({ location: ev.target.value })
                          }
                        />
                        <span className='icon is-small is-left'>
                          <i className='fas fa-map-marker' />
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
                <div className='field'>
                  <div className='control'>
                    <textarea
                      className='textarea'
                      placeholder='Pedido de Proposta/Esclarecimento'
                      value={this.state.request}
                      onChange={ev =>
                        this.setState({
                          request: ev.target.value,
                          requestErr: '',
                        })
                      }
                    />
                  </div>
                  <p className='help is-danger'>{this.state.requestErr}</p>
                </div>
                <nav className='level'>
                  <div className='level-left'>
                    <div className='level-item'>
                      <button
                        className={`button is-primary ${this.state.isLoading}`}
                        onClick={this.handleSubmit}
                      >
                        Enviar
                      </button>
                    </div>
                    <div className='level-item content is-small'>
                      <p className='is-danger'>{this.state.completeMsg}</p>
                    </div>
                  </div>
                </nav>
              </div>
            </div>
          </div>
        </section>
      </Element>
    );
  }
}

export default Contacts;
