import React, { useEffect } from 'react';
import ProductsTable from './ProductsTable';
import productsList from '../../api/productsList';
import './ProductsModal.scss';


const ProductsModal = ({ id, title, closeModal, onRequestContact }) => {
  const filteredList = React.useMemo(
    () => productsList.filter(prod => prod.ind === id),
    [id],
  );

  const [selected, setSelected] = React.useState([]);

  useEffect(() => {
    !id && setSelected([])
  }, [id]
  );

  const onSelectProduct = (prodId) => {
    const index = selected.indexOf(prodId);

    if (index === -1) {
      setSelected([...selected, prodId]);
    } else {
      const sliced = [...selected];
      sliced.splice(index, 1);
      setSelected(sliced);
    }
  };

  const renderContactButton = () => {
    const prodSelectedClass = selected.length > 0 ? '' : ' is-outlined';
    return (
      <button
        className={`ContactButton button is-primary is-small ${prodSelectedClass}`}
        onClick={() => onRequestContact(selected)}
      >
        Peça-nos mais informações
      </button>
    );
  };

  const activeClass = id ? ' is-active' : ''; 

  return (
    <div className={'modal' + activeClass}>
      <div className='modal-background' />
      <div className='modal-card'>
        <header className='modal-card-head'>
          <p className='modal-card-title'>{title}</p>

          {renderContactButton()}

          <button className='delete' aria-label='close' onClick={closeModal} />
        </header>
        <section className='modal-card-body'>
          <ProductsTable
            filteredList={filteredList}
            selected={selected}
            onSelectProduct={onSelectProduct}
          />
        </section>
        <footer className='modal-card-foot content is-small'>
          <p>Rioquimica - Produtos Quimicos de Manutenção Industrial, Lda</p>
        </footer>
      </div>
    </div>
  );
};

export default ProductsModal;
