/* eslint-disable no-empty-character-class */
export const phoneNumValidator = (input) => {
  const pattern = /^(2|9)\d{8}$/g;
  return pattern.test(input);
};

export const emailValidator = (input) => {
  const pattern = /^[A-Z0-9a-z._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,20}$/g;
  return pattern.test(input);
};
/* eslint-enable */
