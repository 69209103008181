import React from 'react';
import './ProductsTable.scss';


const ProductsTable = ({ filteredList, selected, onSelectProduct }) => {
  const renderProductsList = () => {
    return filteredList.map(product => {
      const selectedClass =
        selected.indexOf(product.prod) !== -1 ? 'is-selected' : '';
      return (
        <tr
          key={product.prod}
          className={selectedClass}
          onClick={() => onSelectProduct(product.prod)}
        >
          <th className='Codigo'>{product.prod}</th>
          <td>{product.desc}</td>
        </tr>
      );
    });
  };

  return (
    <div className='ProductsTable content is-small'>
      <table className='table is-striped is-fullwidth'>
        <thead>
          <tr>
            <th>Produto</th>
            <th>Descrição</th>
          </tr>
        </thead>
        <tbody>{renderProductsList()}</tbody>
      </table>
    </div>
  );
};

export default ProductsTable;
