const setMessage = (selected) => {
  if (!selected) {
    return '';
  }

  const list = selected.map(prod => `\n- ${prod}`);
  const message = `Bom dia,\nGostaria de obter mais informações sobre os seguintes produtos:${list}\n\nObrigado(a)`;
  return message;
};

export default setMessage;
