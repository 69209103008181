const productsList = [
  {
    id: 1,
    ind: 'rest',
    prod: 'DETT-AIR/C',
    desc: 'Desodorizante, Desinfetante e Purificador de Ar c/aroma a coco',
  },
  {
    id: 2,
    ind: 'rest',
    prod: 'DETT-AIR/L',
    desc: 'Desodorizante, Desinfetante e Purificador de Ar c/aroma a limão',
  },
  {
    id: 3,
    ind: 'rest',
    prod: 'DETT-AIR/M',
    desc: 'Desodorizante, Desinfetante e Purificador de Ar c/aroma a menta',
  },
  {
    id: 4,
    ind: 'rest',
    prod: 'DETT-ALCA',
    desc: 'Detergente para Limpeza de Alcatifas',
  },
  {
    id: 5,
    ind: 'rest',
    prod: 'DETT-ALCA/M',
    desc: 'Detergente para Limpeza de Alcatifas em Máquina',
  },
  {
    id: 6,
    ind: 'rest',
    prod: 'DETT-ALCA/S',
    desc: 'Produto Anti estático para Limpeza de Alcatifas e Estofos',
  },
  {
    id: 7,
    ind: 'rest',
    prod: 'DETT-AMACIADOR',
    desc: 'Detergente Amaciador de Roupa',
  },
  {
    id: 8,
    ind: 'rest',
    prod: 'DETT-ASMQ',
    desc: 'Abrilhantador e Secantante para Máquinas de Loiça',
  },
  {
    id: 9,
    ind: 'rest',
    prod: 'DETT-BAK',
    desc: 'Gel para Lavagem,Desinfeção de Mãos e Utensílios',
  },
  {
    id: 10,
    ind: 'rest',
    prod: 'DETT-BIO-I',
    desc: 'Tratamento Biológico, Limpeza e Conservação de Sistemas de Esgotos',
  },
  {
    id: 11,
    ind: 'rest',
    prod: 'DETT-BRIO',
    desc: 'Revestimento, Abrilhantador e Protetor de Superfícies',
  },
  {
    id: 12,
    ind: 'rest',
    prod: 'DETT-CAPE/S',
    desc: 'Decapante para Tintas e Vernizes Tixotrópico',
  },
  {
    id: 13,
    ind: 'rest',
    prod: 'DETT-CERACRIL',
    desc: 'Cera para Pavimentos Auto-Brilhante',
  },
  {
    id: 14,
    ind: 'rest',
    prod: 'DETT-CERACRIL/M',
    desc: 'Cera Amarela para Pavimentos Auto-Brilhante',
  },
  {
    id: 15,
    ind: 'rest',
    prod: 'DETT-CERACRIL/T',
    desc: 'Cera Vermelha para Pavimentos Auto-Brilhante',
  },
  {
    id: 16,
    ind: 'rest',
    prod: 'DETT-CID',
    desc: 'Desincrustante Químico para Equipamentos Industriais',
  },
  {
    id: 17,
    ind: 'rest',
    prod: 'DETT-CREME',
    desc: 'Sabonete Líquido e Shampoo de Duche',
  },
  {
    id: 18,
    ind: 'rest',
    prod: 'DETT-CREME/V',
    desc: 'Sabonete Líquido, aroma a morango',
  },
  {
    id: 19,
    ind: 'rest',
    prod: 'DETT-DESIN',
    desc: 'Produto em Pastilhas para Desinfeção Alimentar',
  },
  {
    id: 20,
    ind: 'rest',
    prod: 'DETT-DLX',
    desc: 'Produto Alcalino Clorado para Limpeza e Desinfeção Geral',
  },
  {
    id: 21,
    ind: 'rest',
    prod: 'DETT-DS',
    desc: 'Detergente Desinfetante e Desengordurante',
  },
  { id: 22, ind: 'rest', prod: 'DETT-FOAM', desc: 'Produto Alcalino Clorado' },
  {
    id: 23,
    ind: 'rest',
    prod: 'DETT-FRIO',
    desc: 'Detergente Desinfetante para a Industria Alimentar',
  },
  { id: 24, ind: 'rest', prod: 'DETT-GEL', desc: 'Lava-Mãos Industrial' },
  {
    id: 25,
    ind: 'rest',
    prod: 'DETT-GERMI',
    desc: 'Detergente Desinfetante Desodorizante',
  },
  {
    id: 26,
    ind: 'rest',
    prod: 'DETT-GOR/F',
    desc: 'Desengordurante de Gorduras Queimadas',
  },
  {
    id: 27,
    ind: 'rest',
    prod: 'DETT-INDUSTRIAL',
    desc: 'Lava-Mãos para Limpeza e Proteção da Pele na Industria',
  },
  {
    id: 28,
    ind: 'rest',
    prod: 'DETT-INO',
    desc: 'Detergente Abrilhantador de Inox',
  },
  {
    id: 29,
    ind: 'rest',
    prod: 'DETT-INOX',
    desc: 'Desengordurante Lustrador de Superfícies em Aço Inox',
  },
  {
    id: 30,
    ind: 'rest',
    prod: 'DETT-LAVA TUDO',
    desc: 'Detergente Desinfetante Amoniacal',
  },
  {
    id: 31,
    ind: 'rest',
    prod: 'DETT-LAVA TUDO/ES',
    desc: 'Detergente Desinfetante Biodegradável',
  },
  {
    id: 32,
    ind: 'rest',
    prod: 'DETT-LAVATUDO/SC',
    desc: 'Detergente Desinfetante Bioálcool',
  },
  {
    id: 33,
    ind: 'rest',
    prod: 'DETT-LAVINCERA',
    desc: 'Produto de Manutenção para Pavimentos Encerados',
  },
  {
    id: 34,
    ind: 'rest',
    prod: 'DETT-LGN',
    desc: 'Detergente Multifuncional Biodegradável',
  },
  {
    id: 35,
    ind: 'rest',
    prod: 'DETT-LGS',
    desc: 'Detergente Super-Concentrado para Lavagens Gerais e Loiça',
  },
  {
    id: 36,
    ind: 'rest',
    prod: 'DETT-LIX',
    desc:
      'Tratamento Emulsionável de Águas Residuais, Elimina Maus Cheiros e é Repelente de Insetos',
  },
  {
    id: 37,
    ind: 'rest',
    prod: 'DETT-LLMQ/CL',
    desc: 'Detergente Desinfetante Líquido para Lavagem Automática de Loiça',
  },
  {
    id: 38,
    ind: 'rest',
    prod: 'DETT-LLMQ/S',
    desc: 'Detergente Líquido para Máquinas Automáticas de Loiça',
  },
  {
    id: 39,
    ind: 'rest',
    prod: 'DETT-LML',
    desc: 'Lavagem Manual de Louça Multifuncional',
  },
  { id: 40, ind: 'rest', prod: 'DETT-MISS', desc: 'Desincrustante Orgânico' },
  {
    id: 41,
    ind: 'rest',
    prod: 'DETT-ODOR',
    desc: 'Detergente Bactericida,Desinfetante e Desodorizante',
  },
  {
    id: 42,
    ind: 'rest',
    prod: 'DETT-PLASTAR',
    desc: 'Produto para Tratamento de Relva e Plantas Ornamentais',
  },
  {
    id: 43,
    ind: 'rest',
    prod: 'DETT-PLUS/C',
    desc:
      'Produto de Limpeza de Vidros e Superfícies Espelhadas c/aroma a coco',
  },
  {
    id: 44,
    ind: 'rest',
    prod: 'DETT-PLUS/L',
    desc:
      'Produto de Limpeza de Vidros e Superfícies Espelhadas c/aroma a limão',
  },
  {
    id: 45,
    ind: 'rest',
    prod: 'DETT-PLUS/ES',
    desc: 'Revestimento Abrilhantador e Protetor de Superfícies',
  },
  {
    id: 46,
    ind: 'rest',
    prod: 'DETT-SAN',
    desc: 'Limpeza e Desinfeção de Instalações Sanitárias Dupla Ação',
  },
  {
    id: 47,
    ind: 'rest',
    prod: 'DETT-SLICK/M',
    desc: 'Abrilhantador e Protetor de Madeiras',
  },
  {
    id: 48,
    ind: 'rest',
    prod: 'DETT-SOL',
    desc: 'Detergente Industrial Concentrado',
  },
  { id: 49, ind: 'rest', prod: 'DETT-SPEED', desc: 'Desentupidor de Drenos' },
  {
    id: 50,
    ind: 'rest',
    prod: 'DETT-TOTAL',
    desc: 'Produto Alcalino para Limpezas Gerais',
  },
  {
    id: 51,
    ind: 'rest',
    prod: 'DETT-UNIVERSAL',
    desc:
      'Multiusos-Ecobiologico-Profissional/ Detergente- Algicida-Biocida-Desengordurante-Desinfetante e Slimicida',
  },
  {
    id: 52,
    ind: 'rest',
    prod: 'DETT-50',
    desc: 'Desengordurante Hidrossolúvel Alcalino',
  },
  {
    id: 53,
    ind: 'rest',
    prod: 'DETT-50/S',
    desc: 'Detergente Desengordurante,Espumante,Biodegradável de Uso Geral',
  },
  {
    id: 54,
    ind: 'const',
    prod: 'DETT-AQUASTOP',
    desc: 'Redutor de Água Fluidificante e Impermeabilizante para Betão',
  },
  {
    id: 55,
    ind: 'const',
    prod: 'DETT-BET',
    desc: 'Impermeabilizante Betuminoso de Base Aquosa',
  },
  {
    id: 56,
    ind: 'const',
    prod: 'DETT-CAPE',
    desc: 'Decapante Aquoso para Ceras de Pavimento',
  },
  {
    id: 57,
    ind: 'const',
    prod: 'DETT-CAPE/G',
    desc: 'Decapante de Tintas e Vernizes',
  },
  {
    id: 58,
    ind: 'const',
    prod: 'DETT-CAPE/S',
    desc: 'Decapante para Tintas e Vernizes Tixotrópico',
  },
  {
    id: 59,
    ind: 'const',
    prod: 'DETT-CERACRIL',
    desc: 'Cera para Pavimentos Auto-Brilhante',
  },
  {
    id: 60,
    ind: 'const',
    prod: 'DETT-CID',
    desc: 'Desincrustante Químico para Equipamentos Industriais',
  },
  {
    id: 61,
    ind: 'const',
    prod: 'DETT-CIM',
    desc: 'Desincrustante Químico Ácido',
  },
  {
    id: 62,
    ind: 'const',
    prod: 'DETT-CIMÁQUA',
    desc: 'Produto Cimentício para Tamponagem de Água',
  },
  {
    id: 63,
    ind: 'const',
    prod: 'DETT-CIMELAST',
    desc:
      'Ligante para Revestimento e Impermeabilização de Superfícies, Cimento, Betão e Cerâmica',
  },
  {
    id: 64,
    ind: 'const',
    prod: 'DETT-CIMSEAL',
    desc: 'Produto Cimentício para Impermeabilização de Muralhas em Betão',
  },
  {
    id: 65,
    ind: 'const',
    prod: 'DETT-DESCOL',
    desc: 'Decapante para Tintas, Vernizes e Colas',
  },
  {
    id: 66,
    ind: 'const',
    prod: 'DETT-DUR',
    desc: 'Verniz de Poliuretano para Parquet',
  },
  {
    id: 67,
    ind: 'const',
    prod: 'DETT-DUR/C',
    desc: 'Resina Impermeabilizante e Endurecedora de Betão e Cimento',
  },
  {
    id: 68,
    ind: 'const',
    prod: 'DETT-DUR/F',
    desc:
      'Impermeabilizante Endurecedor e Revestimento de Asfalto, Betão e Cimento de dois Componentes ',
  },
  {
    id: 69,
    ind: 'const',
    prod: 'DETT-DUR/S',
    desc: 'Tinta Epóxida Impermeabilizante e Endurecedora de Cimento e Betão',
  },
  {
    id: 70,
    ind: 'const',
    prod: 'DETT-FER',
    desc: 'Desengordurante Desoxidante e Passivante',
  },
  { id: 71, ind: 'const', prod: 'DETT-FIBRA', desc: 'Fibras de Polipropileno' },
  {
    id: 72,
    ind: 'const',
    prod: 'DETT-FIL',
    desc:
      'Impermeabilizante para Alvenaria, Betão, Cimento, Madeira e outros Materiais Porosos',
  },
  {
    id: 73,
    ind: 'const',
    prod: 'DETT-FIL/AQ',
    desc: 'Hidrofugante para Cimento,Telhas,Tijoleira e Pavimentos',
  },
  {
    id: 74,
    ind: 'const',
    prod: 'DETT-FLOR',
    desc: 'Prevenção e Elimiminação de Eflorescências/Salitres',
  },
  {
    id: 75,
    ind: 'const',
    prod: 'DETT-FUN',
    desc: 'Fungicida, Algicida e Bactericida',
  },
  { id: 76, ind: 'const', prod: 'DETT-GEL', desc: 'Lava-Mãos Industrial' },
  {
    id: 77,
    ind: 'const',
    prod: 'DETT-GRAFITI',
    desc: 'Removedor de Tintas de Grafitis',
  },
  {
    id: 78,
    ind: 'const',
    prod: 'DETT-HIDRO',
    desc: 'Hidrofugante para Argamassas',
  },
  {
    id: 79,
    ind: 'const',
    prod: 'DETT-INDUSTRIAL',
    desc: 'Lava-Mãos para Limpeza e Proteção da Pele na Industria',
  },
  {
    id: 80,
    ind: 'const',
    prod: 'DETT-IZO',
    desc: 'Verniz Isolante e Abrilhantador',
  },
  {
    id: 81,
    ind: 'const',
    prod: 'DETT-LACA',
    desc: 'Tratamento Anticorrosão em Todo Tipo de Metais',
  },
  {
    id: 82,
    ind: 'const',
    prod: 'DETT-LAVINCERA',
    desc: 'Produto de Manutenção para Pavimentos Encerados',
  },
  {
    id: 83,
    ind: 'const',
    prod: 'DETT-LGC',
    desc: 'Detergente Concentrado Líquido Alcalino',
  },
  {
    id: 84,
    ind: 'const',
    prod: 'DETT-LGC/S',
    desc: 'Detergente Líquido  Multiusos Fortemente Alcalino',
  },
  {
    id: 85,
    ind: 'const',
    prod: 'DETT-MIL',
    desc:
      'Solvente Dielétrico e Universal p/ Limpeza de Equipamento Elétrico c/ Rigidez máxima 35 000volts',
  },
  {
    id: 86,
    ind: 'const',
    prod: 'DETT-MIL/S',
    desc:
      'Desengordurante Dielétrico Universal p/Limpeza de Equipamento Eléctrico e Electrónico c/Rigidez max. 9000volts',
  },
  { id: 87, ind: 'const', prod: 'DETT-MISS', desc: 'Desincrustante Orgânico' },
  {
    id: 88,
    ind: 'const',
    prod: 'DETT-MOL',
    desc: 'Descofrante para Indústria de Construção Civil',
  },
  {
    id: 89,
    ind: 'const',
    prod: 'DETT-MOL/S',
    desc: 'Descofrante e Antiaderente para Betão',
  },
  {
    id: 90,
    ind: 'const',
    prod: 'DETT-NAFTA',
    desc: 'Dissolvente de Alcatrão e Naftas',
  },
  {
    id: 91,
    ind: 'const',
    prod: 'DETT-PEN',
    desc: 'Óleo Penetrante e Lubrificante',
  },
  {
    id: 92,
    ind: 'const',
    prod: 'DETT-PINT',
    desc: 'Primário Impermeabilizante, Hidrofugante para Reboco e Betão',
  },
  {
    id: 93,
    ind: 'const',
    prod: 'DETT-PLAST',
    desc: 'Aditivo Plastificante e Hidratante para Betão e Argamassas',
  },
  {
    id: 94,
    ind: 'const',
    prod: 'DETT-PLASTACE',
    desc: 'Plastificante e Acelerador para Argamassas e Betão',
  },
  {
    id: 95,
    ind: 'const',
    prod: 'DETT-PLASTADER',
    desc: 'Promotor de Aderência e Aditivo para Argamassas',
  },
  {
    id: 96,
    ind: 'const',
    prod: 'DETT-PLASTAR',
    desc: 'Produto para Tratamento de Relva e Plantas Ornamentais',
  },
  {
    id: 97,
    ind: 'const',
    prod: 'DETT-PLUS/C',
    desc:
      'Produto de Limpeza de Vidros e Superfícies Espelhadas c/aroma a coco',
  },
  {
    id: 98,
    ind: 'const',
    prod: 'DETT-PLUS/L',
    desc:
      'Produto de Limpeza de Vidros e Superfícies Espelhadas c/aroma a limão',
  },
  { id: 99, ind: 'const', prod: 'DETT-REDOX', desc: 'Conversor de Óxido' },
  {
    id: 100,
    ind: 'const',
    prod: 'DETT-SAN',
    desc: 'Limpeza e Desinfeção de Instalações Sanitárias Dupla Ação',
  },
  {
    id: 101,
    ind: 'const',
    prod: 'DETT-SLICK/M',
    desc: 'Abrilhantador e Protetor de Madeiras',
  },
  {
    id: 102,
    ind: 'const',
    prod: 'DETT-TEX',
    desc: 'Produto Específico para Evitar Infiltrações',
  },
  {
    id: 103,
    ind: 'const',
    prod: 'DETT-23',
    desc: 'Removedor e Protetor de Humidades em Equipamentos Elétricos',
  },
  {
    id: 104,
    ind: 'const',
    prod: 'DETT-50',
    desc: 'Desengordurante Hidrossolúvel Alcalino',
  },
  {
    id: 105,
    ind: 'lava',
    prod: 'DETT-AMACIADOR',
    desc: 'Detergente Amaciador de Roupa',
  },
  {
    id: 106,
    ind: 'lava',
    prod: 'DETT-HIPO',
    desc: 'Branqueador Clorado para Lavagem Automática de Roupa',
  },
  {
    id: 107,
    ind: 'lava',
    prod: 'DETT-LRMQ/10',
    desc: 'Matéria Ativa Líquida para Lavagem Mecânica de Roupa',
  },
  {
    id: 108,
    ind: 'lava',
    prod: 'DETT-NOL',
    desc: 'Branqueador e Desinfetante de Roupa',
  },
  {
    id: 109,
    ind: 'lava',
    prod: 'DETT-NOL/P',
    desc: 'Branqueador e Desinfetante de Roupa em Pó',
  },
  {
    id: 110,
    ind: 'lava',
    prod: 'DETT-SUPER',
    desc: 'Detergente em Pó para Máquinas de Roupa Industriais e Domésticas',
  },
  {
    id: 111,
    ind: 'lava',
    prod: 'DETT-SUPERTAED',
    desc: 'Detergente em Pó para Máquinas de Roupa',
  },
  {
    id: 112,
    ind: 'agua',
    prod: 'DETT-CID',
    desc: 'Desincrustante Químico para Equipamentos Industriais',
  },
  {
    id: 113,
    ind: 'agua',
    prod: 'DETT-CIM',
    desc: 'Desincrustante Químico Ácido',
  },
  {
    id: 114,
    ind: 'agua',
    prod: 'DETT-GERMI',
    desc: 'Detergente Desinfetante Desodorizante',
  },
  {
    id: 115,
    ind: 'agua',
    prod: 'DETT-LIX',
    desc:
      'Tratamento Emulsionável de Águas Residuais, Elimina Maus Cheiros e é Repelente de Insetos',
  },
  {
    id: 116,
    ind: 'agua',
    prod: 'DETT-REG',
    desc: 'Neutralizante de Efluentes',
  },
  {
    id: 117,
    ind: 'agua',
    prod: 'DETT-SAN',
    desc: 'Limpeza e Desinfeção de Instalações Sanitárias Dupla Ação',
  },
  {
    id: 118,
    ind: 'elect',
    prod: 'DETT-MIL',
    desc:
      'Solvente Dielétrico e Universal p/ Limpeza de Equipamento Elétrico c/ Rigidez máxima 35 000volts',
  },
  {
    id: 119,
    ind: 'elect',
    prod: 'DETT-MIL/S',
    desc:
      'Desengordurante Dielétrico Universal p/Limpeza de Equipamento Eléctrico e Electrónico c/Rigidez max. 9000volts',
  },
  {
    id: 120,
    ind: 'elect',
    prod: 'DETT-MIL/SS',
    desc: 'Desengordurante Neutro',
  },
  {
    id: 121,
    ind: 'elect',
    prod: 'DETT-23',
    desc: 'Removedor e Protetor de Humidades em Equipamentos Elétricos',
  },
  {
    id: 122,
    ind: 'auto',
    prod: 'DETT-ABSS',
    desc: 'Absorvente Granulado Industrial',
  },
  {
    id: 123,
    ind: 'auto',
    prod: 'DETT-AIR/C',
    desc: 'Desodorizante, Desinfetante e Purificador de Ar c/aroma a coco',
  },
  {
    id: 124,
    ind: 'auto',
    prod: 'DETT-AIR/L',
    desc: 'Desodorizante, Desinfetante e Purificador de Ar c/aroma a limão',
  },
  {
    id: 125,
    ind: 'auto',
    prod: 'DETT-AIR/M',
    desc: 'Desodorizante, Desinfetante e Purificador de Ar c/aroma a menta',
  },
  {
    id: 126,
    ind: 'auto',
    prod: 'DETT-AL',
    desc: 'Desengordurante e Abrilhantador de Superfícies de Alumínio',
  },
  {
    id: 127,
    ind: 'auto',
    prod: 'DETT-ALCA/S',
    desc: 'Produto Anti estático para Limpeza de Alcatifas e Estofos',
  },
  {
    id: 128,
    ind: 'auto',
    prod: 'DETT-AUTO',
    desc: 'Shampoo para Lavagem Exterior de Viaturas',
  },
  {
    id: 129,
    ind: 'auto',
    prod: 'DETT-AUTO/S',
    desc: 'Shampoo para Lavagem Exterior de Viaturas Antiestático',
  },
  {
    id: 130,
    ind: 'auto',
    prod: 'DETT-BRILHO',
    desc: 'Abrilhantador e Protetor de Superfícies',
  },
  {
    id: 131,
    ind: 'auto',
    prod: 'DETT-BRILL',
    desc: 'Abrilhantador e Protetor de Borracha',
  },
  {
    id: 132,
    ind: 'auto',
    prod: 'DETT-BRIO',
    desc: 'Revestimento, Abrilhantador e Protetor de Superfícies',
  },
  {
    id: 133,
    ind: 'auto',
    prod: 'DETT-CAPE/G',
    desc: 'Decapante de Tintas e Vernizes',
  },
  {
    id: 134,
    ind: 'auto',
    prod: 'DETT-CAPE/S',
    desc: 'Decapante para Tintas e Vernizes Tixotrópico',
  },
  {
    id: 135,
    ind: 'auto',
    prod: 'DETT-CID',
    desc: 'Desincrustante Químico para Equipamentos Industriais',
  },
  { id: 136, ind: 'auto', prod: 'DETT-DIS', desc: 'Dissolvente Orgânico' },
  {
    id: 137,
    ind: 'auto',
    prod: 'DETT-DUR/S',
    desc: 'Tinta Epóxida Impermeabilizante e Endurecedora de Cimento e Betão',
  },
  {
    id: 138,
    ind: 'auto',
    prod: 'DETT-ECOMOTOR',
    desc: 'Desengordurante Ativo de Limpezas Mecânicas',
  },
  {
    id: 139,
    ind: 'auto',
    prod: 'DETT-FER',
    desc: 'Desengordurante Desoxidante e Passivante',
  },
  { id: 140, ind: 'auto', prod: 'DETT-GAS', desc: 'Aditivo para Gasóleo' },
  { id: 141, ind: 'auto', prod: 'DETT-GEL', desc: 'Lava-Mãos Industrial' },
  {
    id: 142,
    ind: 'auto',
    prod: 'DETT-GRAFITI',
    desc: 'Removedor de Tintas de Grafitis',
  },
  {
    id: 143,
    ind: 'auto',
    prod: 'DETT-INDUSTRIAL',
    desc: 'Lava-Mãos para Limpeza e Proteção da Pele na Industria',
  },
  {
    id: 144,
    ind: 'auto',
    prod: 'DETT-INOX',
    desc: 'Desengordurante Lustrador de Superfícies em Aço Inox',
  },
  {
    id: 145,
    ind: 'auto',
    prod: 'DETT-LGN',
    desc: 'Detergente Multifuncional Biodegradável',
  },
  {
    id: 146,
    ind: 'auto',
    prod: 'DETT-LGS',
    desc: 'Detergente Super-Concentrado para Lavagens Gerais e Loiça',
  },
  {
    id: 147,
    ind: 'auto',
    prod: 'DETT-MIL/S',
    desc:
      'Desengordurante Dielétrico Universal p/Limpeza de Equipamento Eléctrico e Electrónico c/Rigidez max. 9000volts',
  },
  { id: 148, ind: 'auto', prod: 'DETT-MIL/SS', desc: 'Desengordurante Neutro' },
  {
    id: 149,
    ind: 'auto',
    prod: 'DETT-MOTORES/SC',
    desc: 'Desengordurante para Motores e Chassis',
  },
  {
    id: 150,
    ind: 'auto',
    prod: 'DETT-NAFTA',
    desc: 'Dissolvente de Alcatrão e Naftas',
  },
  {
    id: 151,
    ind: 'auto',
    prod: 'DETT-ÓLEO',
    desc: 'Óleo de Corte Lubrificante e Refrigerante Solúvel',
  },
  {
    id: 152,
    ind: 'auto',
    prod: 'DETT-ÓLEO/EL',
    desc: 'Óleo de Corte Solúvel de Emulsão Leitosa',
  },
  {
    id: 153,
    ind: 'auto',
    prod: 'DETT-PEN',
    desc: 'Óleo Penetrante e Lubrificante',
  },
  {
    id: 154,
    ind: 'auto',
    prod: 'DETT-PLUS/C',
    desc:
      'Produto de Limpeza de Vidros e Superfícies Espelhadas c/aroma a coco',
  },
  {
    id: 155,
    ind: 'auto',
    prod: 'DETT-PLUS/L',
    desc:
      'Produto de Limpeza de Vidros e Superfícies Espelhadas c/aroma a limão',
  },
  {
    id: 156,
    ind: 'auto',
    prod: 'DETT-REC',
    desc:
      'Produto Reciclador do DETT-ECOMOTOR - desengordurante Ativo de Limpezas Mecânicas',
  },
  { id: 157, ind: 'auto', prod: 'DETT-REDOX', desc: 'Conversor de Óxido' },
  {
    id: 158,
    ind: 'auto',
    prod: 'DETT-SAN',
    desc: 'Limpeza e Desinfeção de Instalações Sanitárias Dupla Ação',
  },
  {
    id: 159,
    ind: 'auto',
    prod: 'DETT-SOL',
    desc: 'Detergente Industrial Concentrado',
  },
  { id: 160, ind: 'auto', prod: 'DETT-TERMOL', desc: 'Anticongelante' },
  {
    id: 161,
    ind: 'auto',
    prod: 'DETT-TOTAL',
    desc: 'Produto Alcalino para Limpezas Gerais',
  },
  {
    id: 162,
    ind: 'auto',
    prod: 'DETT-20',
    desc: 'Desengordurante Concentrado para Limpeza a Vapor',
  },
  {
    id: 163,
    ind: 'auto',
    prod: 'DETT-50',
    desc: 'Desengordurante Hidrossolúvel Alcalino',
  },
  {
    id: 164,
    ind: 'auto',
    prod: 'DETT-50/S',
    desc: 'Detergente Desengordurante,Espumante,Biodegradável de Uso Geral',
  },
  {
    id: 165,
    ind: 'auto',
    prod: 'DETT-99',
    desc: 'Descarbonizante e Desengordurante a Frio',
  },
  {
    id: 166,
    ind: 'metal',
    prod: 'DETT-ABSS',
    desc: 'Absorvente Granulado Industrial',
  },
  {
    id: 167,
    ind: 'metal',
    prod: 'DETT-AL',
    desc: 'Desengordurante e Abrilhantador de Superfícies de Alumínio',
  },
  {
    id: 168,
    ind: 'metal',
    prod: 'DETT-CAPE/G',
    desc: 'Decapante de Tintas e Vernizes',
  },
  {
    id: 169,
    ind: 'metal',
    prod: 'DETT-CAPE/S',
    desc: 'Decapante para Tintas e Vernizes Tixotrópico',
  },
  {
    id: 170,
    ind: 'metal',
    prod: 'DETT-CID',
    desc: 'Desincrustante Químico para Equipamentos Industriais',
  },
  {
    id: 171,
    ind: 'metal',
    prod: 'DETT-CIM',
    desc: 'Desincrustante Químico Ácido',
  },
  {
    id: 172,
    ind: 'metal',
    prod: 'DETT-FER',
    desc: 'Desengordurante Desoxidante e Passivante',
  },
  {
    id: 173,
    ind: 'metal',
    prod: 'DETT-FUEL',
    desc: 'Aditivo Multifuncional para Tratamento de Naftas',
  },
  { id: 174, ind: 'metal', prod: 'DETT-GAS', desc: 'Aditivo para Gasóleo' },
  { id: 175, ind: 'metal', prod: 'DETT-GEL', desc: 'Lava-Mãos Industrial' },
  {
    id: 176,
    ind: 'metal',
    prod: 'DETT-INDUSTRIAL',
    desc: 'Lava-Mãos para Limpeza e Proteção da Pele na Industria',
  },
  {
    id: 177,
    ind: 'metal',
    prod: 'DETT-LACA',
    desc: 'Tratamento Anticorrosão em Todo Tipo de Metais',
  },
  {
    id: 178,
    ind: 'metal',
    prod: 'DETT-LGC',
    desc: 'Detergente Concentrado Líquido Alcalino',
  },
  {
    id: 179,
    ind: 'metal',
    prod: 'DETT-LIX',
    desc:
      'Tratamento Emulsionável de Águas Residuais, Elimina Maus Cheiros e é Repelente de Insetos',
  },
  {
    id: 180,
    ind: 'metal',
    prod: 'DETT-MIL',
    desc:
      'Solvente Dielétrico e Universal p/ Limpeza de Equipamento Elétrico c/ Rigidez máxima 35 000volts',
  },
  {
    id: 181,
    ind: 'metal',
    prod: 'DETT-MIL/S',
    desc:
      'Desengordurante Dielétrico Universal p/Limpeza de Equipamento Eléctrico e Electrónico c/Rigidez max. 9000Volts',
  },
  {
    id: 182,
    ind: 'metal',
    prod: 'DETT-MIL/SS',
    desc: 'Desengordurante Neutro',
  },
  {
    id: 183,
    ind: 'metal',
    prod: 'DETT-MOTORES/SC',
    desc: 'Desengordurante para Motores e Chassis',
  },
  {
    id: 184,
    ind: 'metal',
    prod: 'DETT-NAFTA',
    desc: 'Dissolvente de Alcatrão e Naftas',
  },
  {
    id: 185,
    ind: 'metal',
    prod: 'DETT-ÓLEO',
    desc: 'Óleo de Corte Lubrificante e Refrigerante Solúvel',
  },
  {
    id: 186,
    ind: 'metal',
    prod: 'DETT-ÓLEO/EL',
    desc: 'Óleo de Corte Solúvel de Emulsão Leitosa',
  },
  {
    id: 187,
    ind: 'metal',
    prod: 'DETT-PEN',
    desc: 'Óleo Penetrante e Lubrificante',
  },
  { id: 188, ind: 'metal', prod: 'DETT-REDOX', desc: 'Conversor de Óxido' },
  {
    id: 189,
    ind: 'metal',
    prod: 'DETT-SAN',
    desc: 'Limpeza e Desinfeção de Instalações Sanitárias Dupla Ação',
  },
  {
    id: 190,
    ind: 'metal',
    prod: 'DETT-20',
    desc: 'Desengordurante Concentrado para Limpeza a Vapor',
  },
  {
    id: 191,
    ind: 'metal',
    prod: 'DETT-23',
    desc: 'Removedor e Protetor de Humidades em Equipamentos Elétricos',
  },
  {
    id: 192,
    ind: 'metal',
    prod: 'DETT-50',
    desc: 'Desengordurante Hidrossolúvel Alcalino',
  },
  {
    id: 193,
    ind: 'metal',
    prod: 'DETT-99',
    desc: 'Descarbonizante e Desengordurante a Frio',
  },
  {
    id: 194,
    ind: 'alim',
    prod: 'DETT-ABSS',
    desc: 'Absorvente Granulado Industrial',
  },
  {
    id: 195,
    ind: 'alim',
    prod: 'DETT-AIR/C',
    desc: 'Desodorizante, Desinfetante e Purificador de Ar c/aroma a coco',
  },
  {
    id: 196,
    ind: 'alim',
    prod: 'DETT-AIR/L',
    desc: 'Desodorizante, Desinfetante e Purificador de Ar c/aroma a limão',
  },
  {
    id: 197,
    ind: 'alim',
    prod: 'DETT-AIR/M',
    desc: 'Desodorizante, Desinfetante e Purificador de Ar c/aroma a menta',
  },
  {
    id: 198,
    ind: 'alim',
    prod: 'DETT-BAK',
    desc: 'Gel para Lavagem,Desinfeção de Mãos e Utensílios',
  },
  {
    id: 199,
    ind: 'alim',
    prod: 'DETT-BIO-I',
    desc: 'Tratamento Biológico, Limpeza e Conservação de Sistemas de Esgotos',
  },
  {
    id: 200,
    ind: 'alim',
    prod: 'DETT-CID',
    desc: 'Desincrustante Químico para Equipamentos Industriais',
  },
  {
    id: 201,
    ind: 'alim',
    prod: 'DETT-CONT',
    desc: 'Bactericida, Desodorizante e Desinfetante',
  },
  {
    id: 202,
    ind: 'alim',
    prod: 'DETT-DLX',
    desc: 'Produto Alcalino Clorado para Limpeza e Desinfeção Geral',
  },
  {
    id: 203,
    ind: 'alim',
    prod: 'DETT-DS',
    desc: 'Detergente Desinfetante e Desengordurante',
  },
  {
    id: 204,
    ind: 'alim',
    prod: 'DETT-FER',
    desc: 'Desengordurante Desoxidante e Passivante',
  },
  { id: 205, ind: 'alim', prod: 'DETT-FOAM', desc: 'Produto Alcalino Clorado' },
  {
    id: 206,
    ind: 'alim',
    prod: 'DETT-FUEL',
    desc: 'Aditivo Multifuncional para Tratamento de Naftas',
  },
  {
    id: 207,
    ind: 'alim',
    prod: 'DETT-GERMI',
    desc: 'Detergente Desinfetante Desodorizante',
  },
  {
    id: 208,
    ind: 'alim',
    prod: 'DETT-GOR/F',
    desc: 'Desengordurante de Gorduras Queimadas',
  },
  {
    id: 209,
    ind: 'alim',
    prod: 'DETT-INDUSTRIAL',
    desc: 'Lava-Mãos para Limpeza e Proteção da Pele na Industria',
  },
  {
    id: 210,
    ind: 'alim',
    prod: 'DETT-INOX',
    desc: 'Desengordurante Lustrador de Superfícies em Aço Inox',
  },
  {
    id: 211,
    ind: 'alim',
    prod: 'DETT-LAVA TUDO',
    desc: 'Detergente Desinfetante Amoniacal',
  },
  {
    id: 212,
    ind: 'alim',
    prod: 'DETT-LAVA TUDO/ES',
    desc: 'Detergente Desinfetante Biodegradável',
  },
  {
    id: 213,
    ind: 'alim',
    prod: 'DETT-LAVATUDO/SC',
    desc: 'Detergente Desinfetante Bioálcool',
  },
  {
    id: 214,
    ind: 'alim',
    prod: 'DETT-LGC',
    desc: 'Detergente Concentrado Líquido Alcalino',
  },
  {
    id: 215,
    ind: 'alim',
    prod: 'DETT-LGN',
    desc: 'Detergente Multifuncional Biodegradável',
  },
  {
    id: 216,
    ind: 'alim',
    prod: 'DETT-LGS',
    desc: 'Detergente Super-Concentrado para Lavagens Gerais e Loiça',
  },
  {
    id: 217,
    ind: 'alim',
    prod: 'DETT-LIX',
    desc:
      'Tratamento Emulsionável de Águas Residuais, Elimina Maus Cheiros e é Repelente de Insetos',
  },
  {
    id: 218,
    ind: 'alim',
    prod: 'DETT-ODOR',
    desc: 'Detergente Bactericida,Desinfetante e Desodorizante',
  },
  {
    id: 219,
    ind: 'alim',
    prod: 'DETT-SAN',
    desc: 'Limpeza e Desinfeção de Instalações Sanitárias Dupla Ação',
  },
  {
    id: 220,
    ind: 'alim',
    prod: 'DETT-TOTAL',
    desc: 'Produto Alcalino para Limpezas Gerais',
  },
  {
    id: 221,
    ind: 'alim',
    prod: 'DETT-20',
    desc: 'Desengordurante Concentrado para Limpeza a Vapor',
  },
  {
    id: 222,
    ind: 'alim',
    prod: 'DETT-50',
    desc: 'Desengordurante Hidrossolúvel Alcalino',
  },
  {
    id: 223,
    ind: 'alim',
    prod: 'DETT-50/S',
    desc: 'Detergente Desengordurante,Espumante,Biodegradável de Uso Geral',
  },
  {
    id: 224,
    ind: 'outro',
    prod: 'DETT-BIODEF',
    desc: 'Potenciador de Decomposição Ecológica de Cadáveres',
  },
  {
    id: 225,
    ind: 'outro',
    prod: 'DETT-BIO-I',
    desc: 'Tratamento Biológico, Limpeza e Conservação de Sistemas de Esgotos',
  },
  {
    id: 226,
    ind: 'outro',
    prod: 'DETT-EQUÍDEO',
    desc: 'Sabonete Líquido e Shampoo para Cavalos',
  },
  {
    id: 227,
    ind: 'outro',
    prod: 'DETT-EQUÍDEO/S',
    desc: 'Abrilhantador,Desembaraçador e Lustrador para Cavalos',
  },
  {
    id: 228,
    ind: 'outro',
    prod: 'DETT-LIX',
    desc:
      'Tratamento Emulsionável de Águas Residuais, Elimina Maus Cheiros e é Repelente de Insetos',
  },
  {
    id: 229,
    ind: 'outro',
    prod: 'DETT-LGN',
    desc: 'Detergente Multifuncional Biodegradável',
  },
  {
    id: 230,
    ind: 'outro',
    prod: 'DETT-LGC',
    desc: 'Detergente Concentrado Líquido Alcalino',
  },
  {
    id: 231,
    ind: 'outro',
    prod: 'DETT-FUEL',
    desc: 'Aditivo Multifuncional para Tratamento de Naftas',
  },
  { id: 232, ind: 'outro', prod: 'DETT-GAS', desc: 'Aditivo para Gasóleo' },
];

export default productsList;
