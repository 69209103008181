import * as React from 'react';
import './App.css';
import Header from './components/Header/Header';
import Hero from './components/Hero/Hero';
import AboutUs from './components/AboutUs/AboutUs';
import Products from './components/Products/Products';
import Contacts from './components/Contacts/Contacts';
import Footer from './components/Footer/Footer';

function App() {
  const [message, setMessage] = React.useState('');
  return (
    <div className="App">
      <Header />
      <Hero />
      <AboutUs />
      <Products onRequestContact={(message) => setMessage(message)} />
      <Contacts message={message} />
      <Footer />
    </div>
  );
}

export default App;
