import React, { useState } from 'react';
import { Element, scroller } from 'react-scroll';
import './Products.scss';
import ProductsModal from '../ProductsModal/ProductsModal';
import industries from '../../api/industriesList';
import setMessage from '../../api/defaultMessage';


const Products = ({ onRequestContact }) => {
  const [activeCard, setActiveCard] = useState();

  const handleOnRequestContact = React.useCallback(
    (message) => {
      const msg = setMessage(message);
      setActiveCard(undefined);
      onRequestContact(msg);
      scroller.scrollTo('contacts', true);
    },
    [onRequestContact],
  );

  const renderCard = React.useCallback(
    () =>
      industries.map((item) => (
        <div key={item.id} className='column is-one-third'>
          <div className='card'>
            <div
              className='hoverCard has-text-centered'
              onClick={() => setActiveCard(item)}
            >
              <p className='subtitle'>Ver produtos</p>
            </div>
            <div className='card-image'>
              <figure className='image is-2by1'>
                <img src={item.image} alt='' />
              </figure>
            </div>
            <footer className='card-footer'>
              <p className='card-footer-item title is-6 has-text-centered'>
                <span>{item.title}</span>
              </p>
            </footer>
          </div>
        </div>
      )),
      []
  );

  return (
    <div>
      <Element name='products'>
        <section className='section'>
          <div className='container'>
            <div className='columns'>
              <div className='column is-narrow '>
                <h1 className='title'>Os nossos produtos</h1>
              </div>
            </div>
            <div className='Industry columns is-multiline is-variable is-two-thirds-desktop'>
              {renderCard()}
            </div>
          </div>
        </section>
      </Element>
      <ProductsModal
        title={activeCard && activeCard.title}
        id={activeCard && activeCard.id}
        closeModal={() => setActiveCard(undefined)}
        onRequestContact={handleOnRequestContact}
      />
    </div>
  );
};

export default Products;
