import React from 'react';
import './PrivacyPolicy.scss';
import HtmlPrivacyPolicy from './htmlPrivacyPolicy.js';


const PrivacyPolicy = ({ is_active, closeModal }) => {

  const activeClass = is_active ? ' is-active' : '';

  return (
    <div className={'modal' + activeClass}>
      <div className='modal-background' />
      <div className='modal-card'>
        <header className='modal-card-head'>
          <p className='modal-card-title'>Política de Privacidade</p>
          <button className='delete' aria-label='close' onClick={closeModal} />
        </header>
        <section className='modal-card-body'>
            <HtmlPrivacyPolicy />
        </section>
        <footer className='modal-card-foot content is-small'>
          <p>Rioquimica - Produtos Quimicos de Manutenção Industrial, Lda</p>
        </footer>
      </div>
    </div>
  );
}

export default PrivacyPolicy;